.App {
  text-align: center;
}

.App-link {
  color: rgb(34, 183, 183);
  text-decoration: none;
}

.App-link:hover {
  text-decoration: underline;
  font-weight: bolder;
}

.formContainer {
	height: auto;
	width: 100vw;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
}


* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	font-weight: 500;
	font-size: 1.7rem;
	width: 100vw;
	height: 15vh;
	background: linear-gradient(to top, transparent 0%, black 100%);
}

.svg {
	width: 2.3rem;
}

body {
	font-family: 'montserrat', sans-serif;
	line-height: 1.5;
	background: linear-gradient(180deg,#162c34, #1c2b2b ) ;
}


.h1 {
	font-weight: 700;
	font-size: 3.8rem;
	line-height: 1.2;
	margin-bottom: 15px;
}

.showcase {
	height: 100vh;
	display: flex;
	justify-content: center;
	text-align: center;
	color: #fff;
	padding: 0 20px;

}

.video-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.6;
	overflow: hidden;

}

.h3 {
	font-weight: 400;
	font-size: 1.5rem;
}


.video-container video {
	width: 100vw;
	height: auto;
  position: absolute;
	bottom: 0%;
	left: -50%;
	transform: translateX(50%);

	object-fit: cover;
}

.video-container:after {
	content: '';
	z-index: 1;
	height: 100%;
	width: 100vw;
	top: 0;
	left: 0;
	background:  rgba(0, 0, 0, 0.7);
	position: absolute;
}

.overlay {
	position: absolute;
	width: 100vw;
	height: 100vw;
	bottom: 0;
	z-index: 99;
}

.content {
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}


.heading {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 4rem;
	gap: 1rem;
}

.btn {
	background-color: teal;
	width: 13rem;
	height: 2.2rem;
	color: white;
	letter-spacing: 0.06rem;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10rem;
	margin-top: 0.3rem;
}

.btn:hover {
	color: teal;
	background-color: white;
	transition: 0.2s;
}

#about {
	padding: 40px;
	text-align: center;
}

#about p {
	font-size: 1.2rem;
	max-width: 600px;
	margin: auto;
}

#about h2 {
	margin: 30px 0;
	color: rgb(34, 183, 183);
}

.social a {
	margin: 0 5px;
}

.spanTag {
	color: rgb(34, 183, 183)
}

@media only screen and (max-width: 800px) {
	.h1 {
	  font-size: 2.3rem;
	}

	.h3 {
		font-size: 1.3rem;
	  }

	  .logo {
		  margin-bottom: 5rem;
	  }

	.content {
		justify-content: flex-start;
	}
  }