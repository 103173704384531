html {
  scroll-padding-top: 4rem;
}

.form_section {
	display: flex;
	margin-top: 2em;
	min-height: 100vh;
	width: 100%;
	border-bottom: 1px solid lightgrey;
}

a.href_anchor {
	display: block;
    visibility: hidden;
}

/*
section:nth-child(even) {
	flex-direction: row-reverse;
}
*/


.div_desc {
	display: flex;
	font-size: larger;

	flex-direction: column;
	justify-content: flex-start;
	padding: 1em;
	width: 40vw;
}


.div_form {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 1em;
	width: 40vw;
}

.fullNameLabel {
	font-size: 1rem;
	width: 100%;
}

.fullNameInput {
	font-size: 2rem;
	width: 96.5%;
	padding: 0.5rem;
}

.fullNameButton {
	font-size: 1.5rem;
	width: 100%;
	padding: 0.5rem;
	margin-top: 0.5rem;

}

.result_box {
	border: #111111;
	margin-top: 1rem;
	padding: 0.5rem;
	font-size: 1.5rem;
	min-height: 50vh;
}

.neuro {

}

.neuro_title {
	font-weight: bolder;
}

.neuro_row, .data_row {
	font-variant: all-petite-caps;
	font-size: smaller;
	display: block;

}

.data_row:after, .neuro_row:after {
    margin-right: 6px;
}


.emp {
	font-variant: small-caps;
	font-style: oblique;
}

.probability {
	font-variant: small-caps;
	font-size: small;
	font-weight: bolder;
}


.probability:after {
    content: "%";
    margin-right: 6px;
}

.data_title {
	font-weight: bolder;
	font-size: larger;
}

.data_subtitle {
	font-weight: bolder;
	font-size: smaller;
}

.token {
	font-size: larger;
	font-weight: bolder;
}

.label {
	 vertical-align: sub;
	margin-right: 10px;
}

.mflag-icon:hover {
  width: 18px;
  display: inline-block;
  border-radius: 0%;
  -webkit-filter: grayscale(0%);
  -webkit-transition: .5s ease-in-out;
  -moz-filter: grayscale(0%);
  -moz-transition: .5s ease-in-out;
  -o-filter: grayscale(0%);
  -o-transition: .5s ease-in-out;
  filter: grayscale(0%);
}

.mflag-icon {
  width: 14px;
  display: inline-block;
  border-radius: 50%;
  margin: 0px 2px 0px 2px;
  /*-webkit-filter: grayscale(100%);*/
  -webkit-transition: .5s ease-in-out;
  /* -moz-filter: grayscale(100%); */
  -moz-transition: .5s ease-in-out;
  /*-o-filter: grayscale(100%);*/
  -o-transition: .5s ease-in-out;
  /* filter: grayscale(100%); */
}




@media screen and (max-width: 996px) {
	.form_section,
	section:nth-child(even) {
		flex-direction: column;
	}

	.div_desc {
		height: auto;
		width: auto;
		max-width: 100vw;
	}
	.div_form {
		height: auto;
		width: auto;
		max-width: 100vw;
	}

}


.Select-value-label, .Select-option {
  white-space: pre-wrap;
}