.FormsApp {
  background-color: #fefefe;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(18px + 2vmin);
  color: #111111;
}

.Forms {
  font-size: calc(18px + 2vmin);
}

.Input {
  min-width: 95vw;
}

@media only screen and (min-width: 1024px) {
  .Input {
    min-width: 50vw;
  }
}

